
import { debounce } from "debounce";
export default {
  props: ["element"],
  data() {
    return {
      keys: {},
      q: "",
      focus: false,
      lock: false,
      suggestions: [],
      blocks: this.$store.getters.store.eshop.searchBlocks || [
        {
          title: "Προϊόντα",
          width: "34%",
          type: "items",
          fields: "description,manufacturer",
          limit: 16,
          order: "",
        },
        {
          title: "Προτείνουμε",
          type: "suggest",
          width: "33%",
          fields: "family,business_activity,manufacturer",
          limit: 4, //per field
          order: "",
        },
        {
          title: "tags",
          type: "wildcard",
          width: "33%",
          fields: "tag.Επίπεδο",
          limit: 4,
          order: "",
        },
        {
          title: "manufacturer",
          type: "wildcard",
          width: "33%",
          fields: "manufacturer",
          limit: 4,
          order: "",
        },
      ],
      top: 0,
    };
  },
  watch: {
    focus: function (v) {
      if (v) {
        this.topCalc();
        if (!this.$store.getters.isMobile) {
          const scrollbarWidth =
            window.innerWidth - document.documentElement.clientWidth;
          document.body.style.overflow = "hidden";
          document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
        return;
      }
      if (!this.$store.getters.isMobile) {
        document.body.style.overflow = "unset";
        document.body.style.paddingRight = "0px";
      }
    },
    "$nuxt.$route.fullPath": function (v) {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
      this.focus = false;
    },
  },
  created() {},
  methods: {
    close() {
      this.focus = false;
    },
    topCalc() {
      if (process.browser) {
        let selector = this.element ? "#i" + this.element.uid : false;
        let bar = selector
          ? document.querySelector(selector)
          : [...(document.querySelectorAll("#searchBar") || [])].filter(
              (el) => {
                return el.clientHeight;
              }
            )[0];
        if (bar) {
          let rect = bar.getBoundingClientRect();
          this.top = rect.top + rect.height;
        }
      }
    },
    suggest: debounce(async function (e) {
      this.lock =true
      try {
        let temp = await this.$axios.$post(
          `/items/sv/search?store_id=${this.$store.getters.store._id}`,
          {
            q: this.q,
            store_id: this.$store.getters.store._id,
            blocks: this.blocks,
          }
        );
        if (temp) {
          this.blocks = temp;
        }
        
      } catch (error) {
        console.error(error);
      } finally{
        this.lock =false;
      }
    }, 250),
  },
};
